import React from 'react'
import PropTypes from 'prop-types'
import CorkImg from '../../assets/cork-the-halls-m.svg'
import styled, { css } from 'styled-components/macro'
import LogoImg from '../../assets/logo.png'

const LogoMobile = ({ light, ...props }) => {
  return (
    <HeadContainer>
      <LogoContainer {...props} />
      <GalloLogo src={LogoImg} alt="Gallo Winery" />
    </HeadContainer>
  )
}

LogoMobile.propTypes = {
  light: PropTypes.bool,
}
export default LogoMobile

const GalloLogo = styled.img(
  ({ theme }) => css`
    width: 40%;
    max-width: ${theme.space(25)};
    position: absolute;
    margin: 5% auto 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  `,
)
const HeadContainer = styled.div(
  ({ theme }) => css`
    position: relative;
  `,
)
const LogoContainer = styled.div(
  ({ theme }) => css`
    background: url(${CorkImg}) ${theme.color.headerBackground};
    width: 100%;
    padding-bottom: 67.328%;
    width: 100%;
    padding-bottom: 67.328%;
    background-size: auto 100%;
    background-position: top center;
    background-repeat: no-repeat;
  `,
)
