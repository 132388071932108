import { useEffect, useState } from 'react'
// import { debounce } from 'lodash'

// let throttledHandler

// const getThrottledHandler = handler => {
//   if (!throttledHandler)
//     throttledHandler = debounce(handler, 1000 / 60, { trailing: true })
//   return throttledHandler
// }

export default () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const resizeHandler = () => {
    setSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return size
}
