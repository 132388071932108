import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../common/Container'
import Section from '../../common/Section'
import Text from '../../common/Text'
import styled, { css } from 'styled-components/macro'
import Button from '../../common/Button'
import Logo from '../../common/Logo'
import Gallery from '../../common/Gallery'
import QuestionLabel from './QuestionLabel'
import { ROUTES, TYPE_ANSWER_CLICK } from '../../../constants'
import { navigate } from '@reach/router'
import { useAppContext } from '../../../context/AppContext'
import { rem } from 'polished'
import { AnimatePresence, motion } from 'framer-motion'
import { QuestionTransitions } from '../../../style/transitions'
import { UpArrow } from '../../common/Arrow'
import NextButton from '../../../assets/next_question.svg'
import theme from '../../../style/theme'
import LogoMobile from '../../common/LogoMobile'
import { useAnalytics } from 'use-analytics'

const Questions = () => {
  const { track, page } = useAnalytics()
  const {
    data,
    media: { isSmall },
    quiz: {
      answers,
      setAnswer,
      questionId,
      setQuestionId,
      startTime,
      setStartTime,
    },
  } = useAppContext()

  const [hasTracked1, setHasTracked1] = useState(false)
  const [galleryTransitionComplete, setGalleryTransitionComplete] = useState(
    true,
  )

  const questionIndex = questionId - 1
  const currentQuestion = data[questionIndex]
  const atLastQuestion = questionId >= data.length

  const isDisabled =
    answers[questionIndex].length < 1 || !galleryTransitionComplete

  useEffect(() => {
    page()
    if (questionIndex === 0 && !startTime) setStartTime(new Date())
    //eslint-disable-next-line
  }, [questionId])

  useEffect(() => {
    //eslint-disable-next-line
    if (window.pintrk && questionIndex === 1 && !hasTracked1) {
      window.pintrk('track', 'lead', { lead_type: 'quiz_start' })
      setHasTracked1(true)
    }
    //eslint-disable-next-line
  }, [questionIndex])

  const handleNextQuestion = useCallback(() => {
    // GA track
    if (!isDisabled && answers[questionIndex].length > 0) {
      const response = answers[questionIndex][0]
      const { answers: options, title } = data[questionIndex]
      const selection = options[response].text
      const event = `${title}: ${selection}`
      track(event, {
        category: 'click',
      })
    }
    if (!isDisabled && !atLastQuestion) {
      setQuestionId(questionId + 1)
    } else if (atLastQuestion) {
      navigate(ROUTES.RESULTS)
    }
  }, [atLastQuestion, isDisabled, questionId, setQuestionId])

  const handlePrevQuestion = useCallback(() => {
    if (questionId <= data.length) {
      setQuestionId(questionId - 1)
    }
    //eslint-disable-next-line
  }, [questionId, setQuestionId])

  const handleAnswerSelect = useCallback(
    answerIndex => () =>
      setAnswer({
        type: TYPE_ANSWER_CLICK,
        questionIndex,
        answerIndex,
        maxAnswerIndex: currentQuestion.maxAnswers,
        data,
      }),
    //eslint-disable-next-line
    [currentQuestion.maxAnswers, questionIndex, setAnswer],
  )

  return (
    <Section>
      <Container>
        {isSmall ? <LogoMobile /> : <StyledLogo />}
        <ProgressWrapper>
          <Progress width={Math.ceil((questionId / data.length) * 100) + '%'} />
        </ProgressWrapper>
        <Content>
          <AnimatePresence exitBeforeEnter>
            {questionIndex > 0 ? (
              <PrevLink
                key="prevLink"
                onClick={handlePrevQuestion}
                initial={QuestionTransitions.initial}
                animate={QuestionTransitions.animate}
                exit={QuestionTransitions.exit}
                transition={QuestionTransitions.transition(0.5, 0.2)}
              >
                <UpArrow />

                {!isSmall && (
                  <Text
                    color="lightGrey"
                    size={27 / 2}
                    md={15}
                    xxl={21}
                    spacing={rem(-0.5)}
                  >
                    PREVIOUS QUESTION
                  </Text>
                )}
              </PrevLink>
            ) : null}
          </AnimatePresence>

          <AnimatePresence exitBeforeEnter>
            {data
              .filter((q, i) => i === questionIndex)
              .map((question, i) => {
                return (
                  <QuestionLabel
                    text={question.title}
                    questionId={questionId}
                    key={`question_${i}`}
                  />
                )
              })}
          </AnimatePresence>
          <AnswerContainer>
            <Gallery
              answers={answers}
              currentQuestion={currentQuestion}
              questionIndex={questionIndex}
              handleAnswerSelect={handleAnswerSelect}
              handleTransitionComplete={setGalleryTransitionComplete}
            />
          </AnswerContainer>
          <motion.div
            initial={QuestionTransitions.initial}
            animate={QuestionTransitions.animate}
            exit={QuestionTransitions.exit}
            transition={QuestionTransitions.transition(0.2, 1.5)}
          >
            {atLastQuestion ? (
              <Button
                onClick={handleNextQuestion}
                disabled={isDisabled}
                uppercase
                small
                color={theme.color.nextQuestionButton}
              >
                Finish
              </Button>
            ) : (
              <Next
                disabled={isDisabled}
                onClick={handleNextQuestion}
                alt="Next question"
                src={NextButton}
              />
            )}
          </motion.div>
          <Footer>
            &copy;2020 Ernest & Julio Gallo Winery, Modesto, CA. All rights
            reserved.
          </Footer>
        </Content>
      </Container>
    </Section>
  )
}

Questions.propTypes = {}

export default Questions

const StyledLogo = styled(Logo)`
  ${({ theme }) => css`
    background-color: #fff6e7;
  `}
`

const Next = styled.img(
  ({ theme, disabled }) => css`
    transition: opacity 0.3s;
    width: ${theme.space(19.6)};
    cursor: pointer;

    ${theme.media.lg`
      width: ${theme.space(20.6)};
    `};

    ${disabled &&
      css`
        pointer-events: none;
        opacity: 0.5;
        cursor: default;
      `}
  `,
)
const Content = styled.div(
  ({ theme }) => css`
    padding: 0 ${rem(20)} 0;
    ${theme.media.xl`
        padding: 0;
      `};
    > a {
      min-width: ${rem(237)};

      ${theme.media.xl`
        min-width: ${rem(336)};
      `};
    }

    ${theme.media.lg`
      margin-left: 8vw;
    `};
  `,
)

const ProgressWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.color.lightRed};
  `,
)
const Progress = styled.div(
  ({ theme, width = '100%' }) => css`
    width: ${width};
    height: ${rem(6)};
    background: ${theme.color.darkRed};
    transition: width 0.5s ease-in-out;
    ${theme.media.lg`
      height: ${rem(9)};
    `};
  `,
)
const Footer = styled(motion.div)`
  font-size: ${rem(10)};
  padding 2em 0;
  
  ${theme.media.md`
    font-size: ${rem(13)};
  `}
  ${theme.media.lg`
    font-size: ${rem(16)};
  `}
`

const PrevLink = styled(motion.div)`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;

    div {
      transform: translateY(3px);
    }

    opacity: 1;
    transform: none;
    position: absolute;
    margin: 0;
    z-index: 2;

    margin-top: ${theme.space(2.5)};
    right: ${rem(1)};

    ${theme.media.md`
      margin-top: ${theme.space(2)};
      right: 8vw;
    `}
  `};
`

const AnswerContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin: 0 0 ${rem(18)};

    ${theme.media.md`
      margin: ${theme.space(1)} 0 ${rem(10)};
    `};

    ${theme.media.xl`
      margin-bottom: ${rem(18)};
    `};

    .flickity-enabled:focus {
      outline: none !important;
      border: none;
    }
  `}
`
