import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { useAppContext } from '../../../../context/AppContext'
import { QuestionTransitions } from '../../../../style/transitions'
import Logo from '../../../common/Logo'
import LogoMobile from '../../../common/LogoMobile'
import { motion } from 'framer-motion'
import Container from '../../../common/Container'
import { rem } from 'polished'
import Text from '../../../common/Text'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { useAnalytics } from 'use-analytics'

const waitAndExecute = (ms, fn) =>
  new Promise(r =>
    setTimeout(() => {
      fn()
      r()
    }, ms),
  )

const countByAndExecute = (ms, fn) => {
  return setInterval(() => {
    fn()
  }, ms)
}

const ProcessingBoard = () => {
  const { track } = useAnalytics()
  const {
    quiz: {
      answers,
      setResult,
      boardUri,
      boardType,
      clearStoredAnswers,
      startTime,
    },
    media: { isMedium, isSmall },
  } = useAppContext()

  const [ellipsisCount, setEllipsisCount] = useState(3)

  useEffect(() => {
    let count = 0
    const interval = countByAndExecute(250, () => {
      setEllipsisCount(count++ % 4)
    })
    setResult(answers)

    return () => clearInterval(interval)
  }, [answers, setResult])

  useEffect(() => {
    if (boardUri) {
      waitAndExecute(5000, () => {
        // clear stored answers and redirect to board
        clearStoredAnswers()
        if (boardType) {
          let timeEllapsed = ''
          let diff = 0

          if (startTime) {
            diff = differenceInMilliseconds(new Date(), startTime)
            const minutes = Math.floor(diff / 60000)
            const seconds = ((diff % 60000) / 1000).toFixed(0)
            const elapsed = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`

            timeEllapsed = `Completed in ${elapsed}. `
          }

          track('Quiz completed', {
            category: 'click',
            label: `${timeEllapsed}Board served: ${boardType}`,
            value: diff,
          })
        }
        window.location = boardUri
      })
    }
    //eslint-disable-next-line
  }, [boardUri])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.35 }}
    >
      <ProcessContainer>
        <Background />
        <StyledMotion
          initial={QuestionTransitions.initial}
          animate={QuestionTransitions.animate}
          exit={QuestionTransitions.exit}
          transition={QuestionTransitions.transition(0.7, 0)}
        >
          {isSmall ? <LogoMobile light /> : <Logo light />}
        </StyledMotion>
        <BuckleUpContainer
          isSmall={isSmall}
          initial={QuestionTransitions.initial}
          animate={QuestionTransitions.animate}
          exit={QuestionTransitions.exit}
          transition={QuestionTransitions.transition(0.7, 0.25)}
        >
          <Text center xs={17} color="black">
            Check out this Pinterest board for the perfect gift!
            {!isMedium && <br />}
            {[...Array(ellipsisCount).keys()].map(() => '.')}
          </Text>
        </BuckleUpContainer>
      </ProcessContainer>
    </motion.div>
  )
}

ProcessingBoard.propTypes = {}

export default ProcessingBoard

const ProcessContainer = styled(Container)``

const BuckleUpContainer = styled(motion.div)`
  ${({ theme, isSmall }) => css`
    position: ${isSmall ? 'relative' : 'absolute'};
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;

    img {
      width: 70%;
      height: 70%;
      object-fit: contain;
    }

    ${Text} {
      margin-top: ${theme.space(1)};
      width: ${rem(330)};
      text-align: center;

      ${theme.media.md`
        width: ${rem(500)};
        left: ${rem(15)};
        text-align: left;


      `}
    }

    ${theme.media.lg`
      top: ${rem(300)};
      width: 100%;
      
      img {
        width: ${rem(370)}};
        height: ${rem(112)}};
      }
    `};

    ${theme.media.xl`
      img {
        width: ${rem(530)}};
        height: ${rem(160)}};
      }
    
    `};
  `}
`

const StyledMotion = styled(motion.div)`
  position: relative;
`

const Background = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.headerBackground};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${theme.media.lg`
 
    `}
  `}
`
