import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { QuestionTransitions } from '../../../style/transitions'
import OrnamentNumber from '../../../assets/ornament-number.png'
// import Numbers from '../../common/Numbers'
import Text from '../../common/Text'
import { rem } from 'polished'

const QuestionLabel = forwardRef(({ text, questionId }, ref) => {
  return (
    <QuestionContainer
      ref={ref}
      key={`q_${questionId}`}
      initial={QuestionTransitions.initial}
      animate={QuestionTransitions.animate}
      exit={QuestionTransitions.exit}
      transition={QuestionTransitions.transition(0.5, 0.6)}
    >
      <QuestionNumber>
        {/*<Numbers questionId={questionId} />*/}
        <Number>{questionId}.</Number>
      </QuestionNumber>
      <QuestionText>
        <Text.h2 marginTop="1.2em">{text}</Text.h2>
      </QuestionText>
    </QuestionContainer>
  )
})

QuestionLabel.propTypes = {
  text: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
}

export default QuestionLabel

const Number = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: 0;
    padding-bottom: 0.4em;
    font-size: 1.7rem;
    line-height: 2rem;
    /* text-align: revert; */
    margin-right: 0em;
  `,
)
const QuestionText = styled.div`
  padding-left: 0.75rem;
`
const QuestionContainer = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: ${theme.space(1.5)};
    margin-left: ${rem(-13)};
    
    
     ${theme.media.md`
      margin-top: ${theme.space(2.5)};
    `}
    
    ${theme.media.xl`
      margin-top: ${theme.space(4.5)};
    `}
    
    ${theme.mediaAndBelowFold.xl`
        margin-top: ${theme.space(1.5)};        
    `}

    > ${QuestionNumber} {
      letter-spacing: -2px;
    }

    > div:last-child {
      
      
      ${theme.media.lg`
        width: 70%;
      `}
      
      ${Text} {
        margin-left: ${rem(2)};
      }
    }
    
  `}
`

const QuestionNumber = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    
    background: url(${OrnamentNumber});
    margin-top: -1.3rem;
    
    width: 3.1875em;
    padding-bottom: 5.8125em;    
    
    background-size: 100% 100%;
    background-repeat: no-repeat;

    /*svg {
      width: ${rem(36)};
      height: ${rem(36)};
      fill: ${theme.color.turquoise};

      path {
        fill: ${theme.color.turquoise};
      }

      ${theme.media.md`
        width: ${rem(54)};
        height: ${rem(54)};
      `};

      ${theme.media.xxl`
        width: ${rem(74)};
        height: ${rem(74)};
      `};
    }*/
  `}
`
