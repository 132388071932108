import React, { useEffect } from 'react'
import ProcessingBoard from './views/ProcessingBoard'
import Section from '../../common/Section'
import theme from '../../../style/theme'
import { AnimatePresence } from 'framer-motion'
import { useAnalytics } from 'use-analytics'
const Results = () => {
  const { page } = useAnalytics()
  useEffect(() => {
    page()
    //eslint-disable-next-line
  }, [])

  return (
    <Section bg={theme.color.darkRed}>
      <AnimatePresence exitBeforeEnter>
        <ProcessingBoard />
      </AnimatePresence>
    </Section>
  )
}

Results.propTypes = {}

export default Results
