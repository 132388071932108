import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { rem, timingFunctions } from 'polished'
import Text from '../../common/Text'
import { motion } from 'framer-motion'
import theme from '../../../style/theme'
import { QUESTION_IMAGE_EXT } from '../../../constants'

const Answer = ({
  isSelected,
  backgroundImage,
  clickHandler,
  label,
  ...props
}) => {
  return (
    <MotionContainer {...props}>
      <InitContainer>
        <ImageContainer onClick={clickHandler} isSelected={isSelected}>
          <Background
            src={`/images/questions/${backgroundImage}.${QUESTION_IMAGE_EXT}`}
            alt={label}
          />
          <Gradient />
          <Text lineHeightMultiplier={1.15} helv color="white" xs={16} xl={18}>
            {label}
          </Text>
          <CheckMark isSelected={isSelected} />
        </ImageContainer>
      </InitContainer>
    </MotionContainer>
  )
}

Answer.propTypes = {
  isSelected: PropTypes.bool,
  backgroundImage: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default Answer

const MotionContainer = styled(motion.div)`
  ${({ theme }) => css`
    margin-right: ${theme.space(1)};

    &:last-child {
      margin-right: 0;
    }

    ${theme.media.xl`
      margin-right: ${theme.space(3)};
    `};
  `}
`

const CheckMark = styled.img.attrs({
  src: require('../../../assets/checkmark.png'),
})`
  ${({ theme, isSelected }) => css`
    position: absolute;
    top: 0;
    right: 0;
    width: ${rem(70 / 2)};
    height: ${rem(70 / 2)};
    transition: opacity 0.2s;
    opacity: ${isSelected ? '1' : '0'};
  `}
`

const Gradient = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    height: ${rem(40)};
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  `}
`

const Background = styled.img.attrs(({ src }) => ({ src }))`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `}
`
const InitContainer = styled.div`
  margin-top: 20px;
  background-color: ${theme.color.answerBackground};
`
const ImageContainer = styled(motion.div)`
  ${({ theme, isSelected }) => css`
    width: ${rem(275 / 2)};
    height: ${rem(415 / 2)};
    position: relative;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    transition: transform 0.3s ${timingFunctions('easeOutCubic')};
    transform: translate(${isSelected ? `${rem(5)}, ${rem(-10)}` : '0, 0'});

    ${Text} {
      font-family: Arial;
      text-align: center;
      margin: 0 auto ${rem(10)};
      padding: 0 ${rem(5)};
    }

    ${theme.media.xl`
      width: ${rem(200)};
      height: ${rem(310)};
      transform: translate(${isSelected ? `${rem(14)}, ${rem(-20)}` : '0, 0'});
    `};

    ${theme.mediaAndBelowFold.xl`
       width: ${rem(181)};
      height: ${rem(269)};
    `}
  `}
`
