import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import Answer from '../containers/questions/Answer'
import { QuestionTransitions } from '../../style/transitions'
import Flickity from 'react-flickity-component'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import arrow from '../../assets/images/arrow.png'
import { useAppContext } from '../../context/AppContext'
import useResize from '../../hooks/useResize'

const Gallery = ({
  answers,
  currentQuestion,
  questionIndex,
  handleTransitionComplete,
  handleAnswerSelect,
}) => {
  const [flickityRef, setFlickityRef] = useState(null)
  const [showButtons, setShowButtons] = useState(false)
  const {
    media: { isXLarge },
  } = useAppContext()

  const { width } = useResize()

  useEffect(() => {
    if (flickityRef && flickityRef.element) {
      setShowButtons(
        flickityRef.slideableWidth + 250 > window.innerWidth && isXLarge,
      )
    }
  }, [width, isXLarge, flickityRef, currentQuestion])

  useEffect(() => {
    handleTransitionComplete(false)
  }, [handleTransitionComplete, questionIndex])

  useEffect(() => {
    handleTransitionComplete(true)
  }, [handleTransitionComplete])

  const handleGalleryReload = useCallback(() => {
    if (flickityRef) {
      handleTransitionComplete(true)
      setTimeout(() => {
        flickityRef.reloadCells()
        flickityRef.selectCell(0)
        setShowButtons(
          flickityRef.slideableWidth + 250 > window.innerWidth && isXLarge,
        )
      }, 150)
    }
    //eslint-disable-next-line
  }, [flickityRef, handleTransitionComplete])

  return (
    <FlickityStyled
      flickityRef={ref => setFlickityRef(ref)}
      className="questions"
      elementType="div"
      showButtons={showButtons}
      options={{
        cellAlign: 'left',
        pageDots: false,
        prevNextButtons: false,
        contain: true,
        groupCells: true,
        freeScroll: true,
        arrowShape: {},
      }}
    >
      <AnimatePresence exitBeforeEnter onExitComplete={handleGalleryReload}>
        {currentQuestion.answers.map((answer, answerIndex) => {
          const isSelected = answers[questionIndex].some(i => i === answerIndex)
          return (
            <Answer
              animate={QuestionTransitions.animate}
              backgroundImage={answer.id}
              clickHandler={handleAnswerSelect(answerIndex)}
              exit={QuestionTransitions.exit}
              initial={QuestionTransitions.initial}
              isSelected={isSelected}
              key={answer.text}
              label={answer.text}
              transition={QuestionTransitions.transition(
                0.35,
                answerIndex * 0.1,
              )}
            />
          )
        })}
      </AnimatePresence>
    </FlickityStyled>
  )
}

Gallery.propTypes = {
  answers: PropTypes.array,
  currentQuestion: PropTypes.object,
  questionIndex: PropTypes.number,
  handleTransitionComplete: PropTypes.func,
  handleAnswerSelect: PropTypes.func,
}

export default Gallery

const FlickityStyled = styled(Flickity)`
  ${({ theme, showButtons }) => css`

    .questions {
      position: relative;
    
    }
    .flickity-button.flickity-prev-next-button {
      opacity: ${showButtons ? 1 : 0};
      pointer-events: ${showButtons ? 'auto' : 'none'};
      position:absolute;
      z-index: 100;
      background-color: transparent;
      top: ${rem(-70)};
      right: 0;
      border: none;
      width: ${rem(39)};
      height: ${rem(26)};
      background-image: url('${arrow}');
      filter: saturate(0);
      transition: filter .5s, opacity .5s;
      cursor: pointer;
      
      &:hover {
        filter: saturate(1);

      }
      
      svg {
        display: none;
      }
      
      &.next {
      
      }
      
      &.previous {
        transform: translateX(-150%) scaleX(-1);
      }
      
    }
  `}
`
