import styled, { css } from 'styled-components/macro'
import { motion } from 'framer-motion'

const Section = styled(motion.section)`
  ${({ theme, bg = 'transparent' }) => css`
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    background-color: ${bg};
  `}
`

Section.defaultProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.15 },
}

export default Section
