import React, { useEffect } from 'react'
import Section from '../../common/Section'
import { navigate } from '@reach/router'
import { ROUTES } from '../../../constants'
import { useAppContext } from '../../../context/AppContext'
import { useAnalytics } from 'use-analytics'

const Home = () => {
  const { page } = useAnalytics()
  const {
    quiz: { clearStoredAnswers, setStartTime },
  } = useAppContext()

  useEffect(() => {
    page()
    clearStoredAnswers()
    setStartTime(new Date())
    navigate(ROUTES.QUESTIONS, { replace: true })
    //eslint-disable-next-line
  }, [])

  return <Section />
}

Home.propTypes = {}

export default Home
