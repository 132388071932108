import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

const Container = styled.div`
  ${({ theme }) => css`
    h1 {
      max-width: ${rem(950)};
    }

    height: 100%;
  `}
`

export default Container
