import {
  ABRIL_FAT_FACE_REG,
  ALEX_BRUSH_REG,
  DM_SERIF_DISPLAY_ITALIC,
  DM_SERIF_DISPLAY_REGULAR,
} from '../constants/type'

import { css } from 'styled-components/macro'

//AbrilFatface-Regular
import AbrilFatFaceRegularEot from '../fonts/AbrilFatface-Regular.eot'
import AbrilFatFaceRegularTtf from '../fonts/AbrilFatface-Regular.ttf'
import AbrilFatFaceRegularWoff from '../fonts/AbrilFatface-Regular.woff'
import AbrilFatFaceRegularWoff2 from '../fonts/AbrilFatface-Regular.woff2'

//AlexBrush-Regular
import AlexBrushRegularEot from '../fonts/AlexBrush-Regular.eot'
import AlexBrushRegularTtf from '../fonts/AlexBrush-Regular.ttf'
import AlexBrushRegularWoff from '../fonts/AlexBrush-Regular.woff'
import AlexBrushRegularWoff2 from '../fonts/AlexBrush-Regular.woff2'

//DMSerifDisplay-Regular
import DMSerifDisplayRegularEot from '../fonts/DMSerifDisplay-Regular.eot'
import DMSerifDisplayRegularTtf from '../fonts/DMSerifDisplay-Regular.ttf'
import DMSerifDisplayRegularWoff from '../fonts/DMSerifDisplay-Regular.woff'
import DMSerifDisplayRegularWoff2 from '../fonts/DMSerifDisplay-Regular.woff2'

//DMSerifDisplay-Regular
import DMSerifDisplayItalicEot from '../fonts/DMSerifDisplay-Italic.eot'
import DMSerifDisplayItalicTtf from '../fonts/DMSerifDisplay-Italic.ttf'
import DMSerifDisplayItalicWoff from '../fonts/DMSerifDisplay-Italic.woff'
import DMSerifDisplayItalicWoff2 from '../fonts/DMSerifDisplay-Italic.woff2'

export default () => css`
  @font-face {
    font-family: ${ABRIL_FAT_FACE_REG};
    src: url('${AbrilFatFaceRegularEot}');
    src: url('${AbrilFatFaceRegularEot}#iefix') format('embedded-opentype'),
       url('${AbrilFatFaceRegularWoff}') format('woff'),
       url('${AbrilFatFaceRegularWoff2}') format('woff2'),
       url('${AbrilFatFaceRegularTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: ${ALEX_BRUSH_REG};
    src: url('${AlexBrushRegularEot}');
    src: url('${AlexBrushRegularEot}#iefix') format('embedded-opentype'),
       url('${AlexBrushRegularWoff}') format('woff'),
       url('${AlexBrushRegularWoff2}') format('woff2'),
       url('${AlexBrushRegularTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: ${DM_SERIF_DISPLAY_REGULAR};
    src: url('${DMSerifDisplayRegularEot}');
    src: url('${DMSerifDisplayRegularEot}#iefix') format('embedded-opentype'),
       url('${DMSerifDisplayRegularWoff}') format('woff'),
       url('${DMSerifDisplayRegularWoff2}') format('woff2'),
       url('${DMSerifDisplayRegularTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: ${DM_SERIF_DISPLAY_ITALIC};
    src: url('${DMSerifDisplayItalicEot}');
    src: url('${DMSerifDisplayItalicEot}#iefix') format('embedded-opentype'),
       url('${DMSerifDisplayItalicWoff}') format('woff'),
       url('${DMSerifDisplayItalicWoff2}') format('woff2'),
       url('${DMSerifDisplayItalicTtf}')  format('truetype');
    font-style: normal;
    font-weight: normal;
  }
`
