import React from 'react'
import PropTypes from 'prop-types'
import MediaQueryDebug from '../common/MediaQueryDebug'
import Home from './home/Home'
import Questions from './questions/Questions'
import Results from './results/Results'
import GlobalStyle from '../../style/global'
import theme from '../../style/theme'
import { ROUTES } from '../../constants'
import { useAppContext } from '../../context/AppContext'
import { Router, Location } from '@reach/router'
import { ThemeProvider } from 'styled-components/macro'
import { AnimatePresence } from 'framer-motion'

//eslint-disable-next-line
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import { AnalyticsProvider } from 'use-analytics'

const instanceOne = {
  // initialize the 2nd instance with 'instanceName' field set
  ...googleAnalytics({
    trackingId: 'UA-31565041-73',
    instanceName: 'one',
  }),
  // change 'name' plugin to avoid namespace collisions
  ...{
    name: 'google-analytics-one',
  },
}
const analytics = Analytics({
  app: 'pinterest-app',
  plugins: [instanceOne],
})

const FramerRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <AnimatePresence exitBeforeEnter>
        <Router key={location.key} location={location}>
          {children}
        </Router>
      </AnimatePresence>
    )}
  </Location>
)

FramerRouter.propTypes = {
  children: PropTypes.any,
}

const App = () => {
  const { dataReady } = useAppContext()
  return (
    <ThemeProvider theme={theme}>
      <AnalyticsProvider instance={analytics}>
        <GlobalStyle />
        {dataReady && (
          <FramerRouter>
            <Home default path={ROUTES.HOME} />
            <Questions path={`${ROUTES.QUESTIONS}`} />
            <Results path={ROUTES.RESULTS} />
          </FramerRouter>
        )}
      </AnalyticsProvider>
      <MediaQueryDebug />
    </ThemeProvider>
  )
}
App.propTypes = {}

export default App
