import React from 'react'
import PropTypes from 'prop-types'
import LogoImg from '../../assets/logo.png'
import CorkImg from '../../assets/cork-the-halls.svg'

import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

const Logo = ({ light, ...props }) => {
  return (
    <LogoContainer {...props}>
      <Layout>
        <CorkTheHalls src={CorkImg} alt="Cork the Halls" />
        <GalloLogo src={LogoImg} alt="Gallo Winery" />
      </Layout>
    </LogoContainer>
  )
}

Logo.propTypes = {
  light: PropTypes.bool,
}
export default Logo

const Layout = styled.div(
  ({ theme }) => css`
    position: relative;
    min-width: ${rem(156)};
    max-width: 51%;
  `,
)
const CorkTheHalls = styled.img(
  ({ theme }) => css`
    width: 100%;
    position: relative;
  `,
)
const GalloLogo = styled.img(
  ({ theme }) => css`
    width: 40%;
    max-width: ${theme.space(25)};
    position: absolute;
    margin-left: 15.5%;
    top: 50%;
    transform: translateY(-50%);
  `,
)
const LogoContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding-left: ${theme.space(5)};
    padding-bottom: ${theme.space(1.5)};

    ${theme.media.md`
      min-width: ${rem(250)};
      /*margin-bottom: ${theme.space(3.25)};*/
    `};

    ${theme.media.xl`
      /*margin-bottom: ${theme.space(6.5)};*/
    `};

    z-index: 2;
  `,
)
